import React, { useState, useEffect, useContext } from 'react';
import './EvaluationsStatistics.css';
import axios from 'axios';
import Sidebar from '../../webAppComponents/Sidebar/Sidebar';
import AuthContext from '../../context/AuthContext';
import { useTranslation } from "react-i18next";

import BckLogo from '../../webAppAssets/BackgroundLogo.svg';

import TrainingsPageChanger from '../../webAppComponents/TrainingsPageChanger/TrainingsPageChanger'
import GraphsEvaluations from '../../webAppComponents/GraphsEvaluations/GraphsEvaluations';


function EvaluationsStatistics() {
    const cenas = {
        labels: ["a", "b", "c"],
        graph1: {
          line: [1, 2, 3],
          bar1: [4, 5, 6],
          bar2: [7, 8, 9]
        },
        graph2: {
          line: [10, 11, 12],
          bar1: [13, 14, 15],
          bar2: [16, 17, 18]
        },
        graph3: {
          line: [19, 20, 21],
          bar1: [22, 23, 24],
        }
      }

  const { t } = useTranslation(['evaluations-page']);

  const placeBackgroundImages = () => {
    const bckLogo1 = document.getElementById("webapp-create-team-bcklogo1");
    const bckLogo2 = document.getElementById("webapp-create-team-bcklogo2");
    const bckLogo1Height = bckLogo1.offsetHeight;
    bckLogo1.style.top = `calc(60% - ${bckLogo1Height}px)`;
    bckLogo2.style.top = `0%`;
    const bckLogo1Width = bckLogo1.offsetWidth;
    bckLogo1.style.left = `calc(60% - ${bckLogo1Width}px)`;
    bckLogo2.style.left = `75%`;
  }

  useEffect(() => {
    placeBackgroundImages();
    window.addEventListener('resize', placeBackgroundImages);
    return () => {
        window.removeEventListener('resize', placeBackgroundImages);
    }
  }, []);

  return (
    <div className="webapp-evaluations-page-scroll">
        <Sidebar selectedOption="evaluations" />
        <div className="webapp-evaluations-page">
            <TrainingsPageChanger
                selectedPage={1}
                pages={[
                    { label: t('Total'), path: '/webapp/evaluations' },
                    { label: t('Statistics'), path: '/webapp/evaluations-statistics' },
                ]}
            />
            <div className="webapp-create-team-background-images">
                <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo1" />
                <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo2" />
            </div>
            <div className="webapp-evaluations-page-header">
                <h1>{t('Evaluations Statistics')}</h1>
            </div>
            <GraphsEvaluations data={cenas}/>
        </div>
    </div>
  );
}

export default EvaluationsStatistics;
