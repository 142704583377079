import "./GraphsEvaluations.css";

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import DashboardFilters from "../DashboardFilters/DashboardFilters";

import { Chart as ChartJS, CategoryScale, BarElement, LinearScale, PointElement, LineElement, Tooltip, LineController, BarController} from "chart.js";
import { Chart } from "react-chartjs-2";


ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Tooltip, LineController, BarController);

const GraphsEvaluations = ({data, filters, handle, type }) => {
    const { t } = useTranslation(["charts-evaluations"]);

    const [graph1, setGraph1] = useState(null);
    const [graph2, setGraph2] = useState(null);
    const [graph3, setGraph3] = useState(null);

    useEffect(() => {
        setGraph1({
            labels: data.labels,
            datasets: [
                {
                    type: 'bar',
                    label: t("10 meters sprint"),
                    backgroundColor: 'rgb(172, 99, 183)',
                    borderWidth: false,
                    borderRadius: 10,
                    data: data.graph1.bar1,
                    yAxisID: 'y1',
                },
                {
                    type: 'bar',
                    label: t("30 meters sprint"),
                    backgroundColor: 'rgb(163, 207, 113)',
                    data: data.graph1.bar2,
                    borderWidth: false,
                    borderRadius: 10,
                    yAxisID: 'y',
                },
                {
                    type: 'bar',
                    label: t("1,2 meters sprint"),
                    backgroundColor: 'rgb(252, 165, 85)',
                    data: data.graph1.bar3,
                    borderWidth: false,
                    borderRadius: 10,
                    yAxisID: 'y',
                },{
                    type: 'bar',
                    label: t("Agility"),
                    backgroundColor: 'rgb(237, 72, 99)',
                    data: data.graph1.bar4,
                    borderWidth: false,
                    borderRadius: 10,
                    yAxisID: 'y',
                },
            ],
        });

        setGraph2({
            labels: data.labels,
            datasets: [
                {
                    type: 'line',
                    label: t("BMI"),
                    borderColor: 'rgb(252,165,85, 0)',
                    backgroundColor: 'rgb(4, 14, 100)',
                    borderWidth: 5,
                    pointRadius: 6,
                    data: data.graph2.line,
                    yAxisID: 'y1',
                },
                {
                    type: 'bar',
                    label: t("Height in cm"),
                    backgroundColor: 'rgb(105, 120, 249)',
                    data: data.graph2.bar1,
                    borderWidth: false,
                    borderRadius: 10,
                    yAxisID: 'y',
                },
                {
                    type: 'bar',
                    label: t("Weight in kg"),
                    backgroundColor: 'rgb(252, 165, 85)',
                    data: data.graph2.bar2,
                    borderWidth: false,
                    borderRadius: 10,
                    yAxisID: 'y',
                },
            ],
        });

        setGraph3({
            labels: data.labels,
            datasets: [
                {
                    type: 'line',
                    label: t("Yo-Yo"),
                    borderColor: 'rgb(252,165,85, 0)',
                    backgroundColor: 'rgb(4, 14, 100)',
                    borderWidth: 5,
                    pointRadius: 6,
                    data: data.graph3.line,
                    yAxisID: 'y1',
                },
                {
                    type: 'bar',
                    label: t("SJ"),
                    backgroundColor: 'rgb(105, 120, 249)',
                    data: data.graph3.bar1,
                    borderWidth: false,
                    borderRadius: 10,
                    yAxisID: 'y',
                },{
                    type: 'bar',
                    label: t("CMJ"),
                    backgroundColor: 'rgb(252,165,85)',
                    data: data.graph3.bar1,
                    borderWidth: false,
                    borderRadius: 10,
                    yAxisID: 'y',
                }
            ],
        });
        
    }, [data]);

    const options = {
    responsive: true,
    plugins: {
        legend: {
        display: false,
        },
        title: {
        display: false,
        },
    },
    scales: {
        y: {
            type: 'linear',
            display: true,
            position: 'left',
        },
        y1: {
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
                drawOnChartArea: false,
            },
        },
        },
    };

    return (
        <div className="webapp-graphuc">
            <div className="webapp-graphuc-filters">
                {filters?.map((filter) => {
                return(
                    <DashboardFilters name={filter.name} filters={filter.content} handle={handle} type={type}/>
                )
                })}
            </div>
            {graph1 && <div className="webapp-graphuc-content">
                <div className="webapp-graphuc-header">
                    <h2 className="webapp-graphuc-header-title">{t("Speed Tests")}</h2>
                </div>
                <div className="webapp-graphuc-chart">
                    <Chart data={graph1} options={options}/>
                </div>
            </div>}
            {graph2 &&<div className="webapp-graphuc-content">
                <div className="webapp-graphuc-header">
                    <h2 className="webapp-graphuc-header-title">{t("Measurements")}</h2>
                </div>
                <div className="webapp-graphuc-chart">
                    <Chart data={graph2} options={options}/>
                </div>
            </div>}
            {graph3 && <div className="webapp-graphuc-content">
                <div className="webapp-graphuc-header">
                    <h2 className="webapp-graphuc-header-title">{t("Leg Power (SJ - CMJ) and Yo-Yo IR1")}</h2>
                </div>
                <div className="webapp-graphuc-chart">
                    <Chart data={graph3} options={options}/>
                </div>
            </div>}
        </div>
    );
}

export default GraphsEvaluations;